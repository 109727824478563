import { Note } from "./components/note/notes";
import * as fs from "fs";
import * as $ from "jquery";
import * as moment from 'moment';
import * as ko from "knockout";
import '../node_modules/jwt-decode/build/jwt-decode.min.js';
import { Utils } from "./utils";
import { User, UserData, UserDataWrapper } from "./UserData";
import { TreeType, TreeDto } from './components/tree/tree';
import { StrategyDto } from './components/strategy/strategy';
import { Filter } from "./components/case/caseentryoverview";
import Navigo = require("navigo");
import { error } from "util";
import { version } from "punycode";
import { LawFirmEmployeAuthentication } from "./types/LawFirmEmployeAuthentication";
import { LawFirmRegistration } from "./types/LawFirmRegistration";
import { UserVerifyPassword } from "./types/UserVerifyPassword";
import { CreateManualTimeMonitoring } from "./types/CreateManualTimeMonitoring";
import { MoveMailToCase } from "./types/MoveMailToCase";
import { MoveDocToCase } from "./types/MoveDocToCase";
import { CalculateResubmission } from "./types/CalculateResubmission";
import { ResetPassword } from "./types/ResetPassword";
import { VerifyMail } from "./types/VerifyMail";
import { VerifyMailCode } from "./types/VerifyMailCode";
import { GenerateDocument } from "./types/GenerateDocument";
import { SaveDocument } from "./types/SaveDocument";

export class ReturnWrapper<T> {
    Type: string
    Payload: T

    constructor(type: string, payload: T) {
        this.Type = type;
        this.Payload = payload;
    }
}

$.ajaxSetup({
    xhrFields: {
        withCredentials: true
    },
    crossDomain: true
});


export class Routing {
    name: string
    params: any

    constructor(name: string, params: any) {
        this.name = name;
        this.params = params;
    }
}


export class RNSAPI {
    public static config = JSON.parse(fs.readFileSync(__dirname + "/../renocloudURL.json", "utf8"));
    public static origin = RNSAPI.config.renocloud;
    public static base = RNSAPI.origin + "/api"

    private static router = new Navigo(null, true);

    public static User: ko.Observable<User | undefined> = ko.observable(undefined)
    private static UserData: UserData = null;

    public static async receiveUserData() {
        if (!RNSAPI.UserData) {
            let data = (await RNSAPI.getUserData()).Payload;
            if (data.Value) {
                RNSAPI.UserData = (JSON.parse(data.Value));
            } else {
                RNSAPI.UserData = new UserData("foobar");
            }

            return RNSAPI.UserData;
        }
    };

    public static async writeUserData(data: UserData) {
        RNSAPI.UserData = data;
        RNSAPI.setUserData(data);
    }

    public static CurrentRouting: ko.Observable<Routing> = ko.observable<Routing>();

    public static Token = "";

    public static mapTypeToScope = {
        "mandant": "2",
        "gegner": "3",
        "bank": "4",
        "versicherung": "5",
        "behörde": "6",
        "anwalt": "7",
        "gericht": "8",
        "sonstige": "9"
    };

    public static test: number = 0;
    public static AuthenticationFailed: number = 0;

    private static rpcRequest(url: string, data: string, httpType: string = "POST", cType: string = "application/json"): Promise<ReturnWrapper<any>> {
        return new Promise<ReturnWrapper<void>>(function (resolve, reject) {
            if (RNSAPI.AuthenticationFailed === 0) {
                $.ajax(`${RNSAPI.base}${url}`, {
                    data: data,
                    contentType: cType,
                    beforeSend: function (xhr) {
                        xhr.setRequestHeader('access_token', RNSAPI.Token);
                    },
                    method: httpType,
                    type: 'json',
                    error: function (jqXHR, exception) {
                        if (jqXHR.status === 401) {
                            RNSAPI.AuthenticationFailed = RNSAPI.AuthenticationFailed + 1;
                            if (RNSAPI.AuthenticationFailed === 1) {
                                alert("Ihre Session ist abgelaufen, bitte melden Sie sich erneut bei cloud.rainmaker.de an.");
                            }
                            Utils.eraseCookie('access_token');
                            RNSAPI.router.navigate('/login');
                        }
                    }
                }).done(resolve).fail(reject);
            }
        }
        );
    }

    private static rpcUploadRequest(url: string, data: FormData): Promise<ReturnWrapper<any>> {
        return new Promise<ReturnWrapper<void>>(function (resolve, reject) {
            if (RNSAPI.AuthenticationFailed === 0) {
                $.ajax(`${RNSAPI.base}${url}`, {
                    data: data,
                    contentType: false,
                    processData: false,
                    beforeSend: function (xhr) {
                        xhr.setRequestHeader('access_token', RNSAPI.Token);
                    },
                    method: "POST",
                    error: function (jqXHR, exception) {
                        if (jqXHR.status === 401) {
                            RNSAPI.AuthenticationFailed = RNSAPI.AuthenticationFailed + 1;
                            if (RNSAPI.AuthenticationFailed === 1) {
                                alert("Ihre Session ist abgelaufen, bitte melden Sie sich erneut bei cloud.rainmaker.de an.");
                            }
                            Utils.eraseCookie('access_token');
                            RNSAPI.router.navigate('/login');
                        }
                    }
                }).done(resolve).fail(reject);
            }
        }
        );
    }

    public static login(token: string) {
        RNSAPI.Token = token;
        const decodedUser = RNSAPI.getCurrentLoggedInUser();
        if(decodedUser) {
            RNSAPI.User(new User(decodedUser.ShortHandSymbol, decodedUser.Role));
        } else {
            Utils.eraseCookie('access_token');
        }

        Utils.createCookie("access_token", RNSAPI.Token, 1);
    }


    public static loginRegisterApi(url, data): Promise<ReturnWrapper<any>> {
        return new Promise<ReturnWrapper<void>>(function (resolve, reject) {
            $.ajax(`${url}`, {
                data: data,
                processData: false,
                contentType: false,
                // enctype: 'multipart/form-data',
                // contentType: 'application/x-www-form-urlencoded',
                type: "POST",
            }).done(resolve).fail(reject);
        });
    }

    public static checkRecaptcha(url, data): Promise<ReturnWrapper<any>> {
        return new Promise<ReturnWrapper<void>>(function (resolve, reject) {
            $.ajax(`${url}`, {
                data: data,
                processData: false,
                contentType: false,
                // enctype: 'multipart/form-data',
                // contentType: 'application/x-www-form-urlencoded',
                type: "POST",
            }).done(resolve).fail(reject);
        });
    }

    public static getCurrentLoggedInUser() {
        if(RNSAPI.Token) {
            try {
                const decoded = (window as any).jwt_decode(RNSAPI.Token);
                return decoded;
            } catch {
                return null;
            }
        }
        return null;
    }

    public static getAppointments(start: Date, end: Date): Promise<ReturnWrapper<any>> {
        let startString = start.toISOString();
        let endString = end.toISOString();
        let data = JSON.stringify({
            UserId: RNSAPI.User() ? RNSAPI.User().username : "GR",
            StartDate: startString,
            EndDate: endString
        });
        return RNSAPI.rpcRequest("/Appointments/Search", data);
    }

    public static getAppointmentsForCase(caseId: string, start: Date, end: Date): Promise<ReturnWrapper<any>> {
        let startString = start.toISOString();
        let endString = end.toISOString();
        let data = JSON.stringify({
            CaseID: caseId,
            StartDate: startString,
            EndDate: endString
        });
        return RNSAPI.rpcRequest("/Appointments/ForCase", data);
    }

    public static authUser = (authData: LawFirmEmployeAuthentication) => RNSAPI.rpcRequest("/Account/Auth", ko.toJSON(authData), "POST");
    public static registerLawFirm = (registerData: LawFirmRegistration) => RNSAPI.rpcRequest("/Account/Register", ko.toJSON(registerData), "POST");
    public static verifyUserPassword = (verifyData: UserVerifyPassword) => RNSAPI.rpcRequest("/Account/VerifyPassword", ko.toJSON(verifyData), "POST");
    public static uploadAccountImage = (uploadData: any) => RNSAPI.rpcUploadRequest("/Account/UploadImage", uploadData);
    public static getAccountImage = () => RNSAPI.rpcRequest("/Account/GetAccountImage", "", "GET");


    public static removeForeclosureBooking = (id: number) => RNSAPI.rpcRequest("/Foreclosure/DeleteBooking", JSON.stringify({ Id: id }), "DELETE");

    //Forderungskonto
    public static getReceivableAccountData = (caseId: string) => RNSAPI.rpcRequest("/ReceivablesAccount/ForCase?CaseId=" + caseId, null, "GET");
    public static addReceivableAccountData = (booking: any) => RNSAPI.rpcRequest("/ReceivablesAccount/AddReceivables", JSON.stringify(booking), "POST");
    public static editReceivableAccountData = (booking: any) => RNSAPI.rpcRequest("/ReceivablesAccount/UpdateReceivables", JSON.stringify(booking), "POST");
    public static getReceivablePositionById = (positionId: string) => RNSAPI.rpcRequest("/ReceivablesAccount/ForId?id=" + positionId, null, "GET");
    public static deleteReceivablePositionById = (positionId: string) => RNSAPI.rpcRequest("/ReceivablesAccount/DeleteReceivalbesForId?id=" + positionId, null, "DELETE");
    public static getReceivableAccountForCase = (caseId: string, defendant: string, receiptDate: Date) => RNSAPI.rpcRequest("/ReceivablesAccount/Overview", JSON.stringify({ caseId: caseId, defendant: defendant, receiptDate:  receiptDate}), "POST");
    public static printReceivableAccountForCase = (caseId: string, defendant: string, receiptDate: Date) => RNSAPI.rpcRequest("/ReceivablesAccount/Print", JSON.stringify({ caseId: caseId, defendant: defendant, receiptDate:  receiptDate}), "POST");

    public static getCatalogNumbers = () => RNSAPI.rpcRequest("/CatalogNumber/Overview", null, "GET");

    public static getTypesOfInterest = () => RNSAPI.rpcRequest("/TypesOfInterest/Overview", null, "GET");

    public static getMailSenderAccounts = () => RNSAPI.rpcRequest("/Zpe/Sender", "", "GET");
    public static getCasesOverview = (page: any, pageSize: any) => RNSAPI.rpcRequest("/Cases/OverviewFilter", JSON.stringify({ Page: page, PageSize: 100000 }), "POST");


    public static resetAccountPassword = (resetPasswordData: ResetPassword) => RNSAPI.rpcRequest("/Account/ResetPassword", ko.toJSON(resetPasswordData), "POST");
    public static verifyAccountMail = (verifyData: VerifyMail) => RNSAPI.rpcRequest("/Account/VerifyMail", ko.toJSON(verifyData), "POST");
    public static verifyAccountMailCode = (verifyData: VerifyMailCode) => RNSAPI.rpcRequest("/Account/VerifyMailCode", ko.toJSON(verifyData), "POST");

    public static getAppointment = (Id: string) => RNSAPI.rpcRequest("/Appointments/Get", JSON.stringify({ Id: Id }));
    public static createAppointment = (appointment: any) => RNSAPI.rpcRequest("/Appointments/Create", ko.toJSON(appointment));
    public static updateAppointment = (appointment: any) => RNSAPI.rpcRequest("/Appointments/edit", ko.toJSON(appointment));
    public static deleteAppointment = (Id: string) => RNSAPI.rpcRequest("/Appointments/Delete", JSON.stringify({ Id: Id }));
    public static completeAppointment = (Id: string) => RNSAPI.rpcRequest("/Appointments/Complete", JSON.stringify({ Id: Id }));

    public static getDeadlines = (day: moment.Moment) => RNSAPI.rpcRequest("/Deadlines/WorkableOn", JSON.stringify({ Date: day.format("YYYY-MM-DD") }));
    public static getDeadlinesByRange = (start: moment.Moment, end: moment.Moment) => RNSAPI.rpcRequest("/Deadlines/ByRange", JSON.stringify({ StartDate: start.format("YYYY-MM-DD"), stopDate: end.format("YYYY-MM-DD") }));
    public static getDeadlinesForCase = (id: string) => RNSAPI.rpcRequest("/Deadlines/ForCase", JSON.stringify({ CaseId: id }));
    public static getDedlinesForLawfirms = (start: moment.Moment, end: moment.Moment, locations: any) => RNSAPI.rpcRequest("/Deadlines/ByLocationRange", JSON.stringify({ StartDate: start.format("YYYY-MM-DD"), StopDate: end.format("YYYY-MM-DD"), Locations: locations }));
    public static getDeadlineReasons = () => RNSAPI.rpcRequest("/Deadlines/GetReasons", JSON.stringify({}));
    public static createDeadline = (deadline: any) => RNSAPI.rpcRequest("/Deadlines/Create", JSON.stringify(deadline));
    public static calcDeadline = (obj: any) => RNSAPI.rpcRequest("/Deadlines/CalcDeadline", JSON.stringify(obj));
    public static completeDeadline = (deadline: any) => RNSAPI.rpcRequest("/Deadlines/Complete", JSON.stringify(deadline));
    public static extendDeadline = (oldDeadline: any, newDeadlineDate: string, reason="") => {
        let obj = {

            NewDeadLineDate: newDeadlineDate,
            OldDeadLine: oldDeadline,
            Reason: reason
        };

        return RNSAPI.rpcRequest("/Deadlines/Extend", JSON.stringify(obj));
    }
    /*    public static extendResubmission = (oldDeadline: any, newDeadlineDate: string) => {
            let obj = {
                OldDeadLine: oldDeadline,
                NewDeadLineDate: newDeadlineDate
            };
            return RNSAPI.rpcRequest("/Deadlines/Extend", JSON.stringify(obj));
        }  */
    public static delegateDeadline = (oldDeadline: any, clerkId: string) => {
        let obj = {
            DeadLine: oldDeadline,
            NewUser: clerkId
        };
        return RNSAPI.rpcRequest("/Deadlines/Delegate", JSON.stringify(obj));
    }
    public static reportDeadline = (Start: moment.Moment, End: moment.Moment, filter: any) => RNSAPI.rpcRequest("/Deadlines/Report", JSON.stringify({ startDate: Start.format("YYYY-MM-DD"), endDate: End.format("YYYY-MM-DD"), Filter: filter }))
    public static getFristDeadlineReasons = () => RNSAPI.rpcRequest("/Deadlines/GetFristExtensionReasons", JSON.stringify({}));
    public static insFristDeadlineReasons = (reason: string) => RNSAPI.rpcRequest("/Deadlines/InsertFristExtensionReasons", JSON.stringify({ Reason: reason }));
    public static delFristDeadlineReasons = (id: string) => RNSAPI.rpcRequest("/Deadlines/DeleteFristExtensionReasons", JSON.stringify({ Id: id }));


    public static uploadToScanPool = (fileName: string, base64Content: string) => {
        let obj = {
            FileName: fileName,
            FileData: base64Content
        }
        return RNSAPI.rpcRequest("/ScanPool/Upload", JSON.stringify(obj));
    };
    public static getScanPoolByDateInterval = (startDate: moment.Moment, endDate: moment.Moment) => {
        let obj = {
            StartDate: startDate.toISOString(),
            EndDate: endDate.toISOString()
        };
        return RNSAPI.rpcRequest("/ScanPool/SearchAll", JSON.stringify(obj));
    }
    public static getScanPoolEntryByName = (fileName: string) => RNSAPI.rpcRequest("/ScanPool/Get", JSON.stringify({ FileName: fileName }));

    public static getZPE = () => RNSAPI.rpcRequest("/Zpe/Get", JSON.stringify({}));
    public static getMailsByDateInterval = (startDate: moment.Moment, endDate: moment.Moment) => {
        let obj = {
            StartDate: startDate.toISOString(),
            StopDate: endDate.toISOString()
        };
        return RNSAPI.rpcRequest("/Zpe/GetMailRange", JSON.stringify(obj));
    };
    public static getMailById = (accountId: string, mailId: string) => {
        let obj = {
            AccountId: accountId,
            RnsMailID: mailId
        };
        return RNSAPI.rpcRequest("/Zpe/GetMailContent", JSON.stringify(obj));
    }
    public static getMailByECase = (id: string) => RNSAPI.rpcRequest("/Documents/GetMailByRecordID", JSON.stringify({ ECaseRecordId: id }));
    public static sendMail = (mail: any) => RNSAPI.rpcRequest("/Zpe/SendMail", JSON.stringify(mail));
    public static createInbox = (inbox: any) => RNSAPI.rpcRequest("/Zpe/CreateInbox", ko.toJSON(inbox));
    public static updateInbox = (inbox: any) => RNSAPI.rpcRequest("/Zpe/UpdateInbox", ko.toJSON(inbox));
    public static getAllInboxes = () => RNSAPI.rpcRequest("/ZPE/GetAllAccounts", JSON.stringify({}));
    public static getInboxById = (id: string) => RNSAPI.rpcRequest("/ZPE/GetAccount", JSON.stringify({ AccountId: id }));
    public static deleteInboxById = (id: string) => RNSAPI.rpcRequest("/ZPE/DeleteAccount", JSON.stringify({ AccountId: id }));
    //public static deleteMailById = (AccountId: string, EmailID: string) => RNSAPI.rpcRequest("/ZPE/DeleteMail", JSON.stringify({ AccountID: AccountId, RnsMailID: EmailID }));

    public static deleteMailbyId = (accountId: string, mailId: string) => {
        let input = {
            AccountId: accountId,
            RnsMailID: mailId
        };
        return RNSAPI.rpcRequest("/ZPE/DeleteMail", JSON.stringify(input));
    }
    //public static delegateResubmission = (recordId: string) => RNSAPI.rpcRequest("/Cases/UpdateSuggestion", JSON.stringify({ Clerks:clerk }));

    public static getResubmissions = () => RNSAPI.rpcRequest("/Resubmissions/GetAll", JSON.stringify({}));
    public static getResubmissionsByRange = (range: any) => RNSAPI.rpcRequest("/Resubmissions/ByRange", JSON.stringify(range));
    public static getResubmission = (id: string) => RNSAPI.rpcRequest("/Resubmissions/Get", JSON.stringify({ Id: id }));
    public static getResubmissionByCaseId = (id: string) => RNSAPI.rpcRequest("/Resubmissions/GetByCaseId", JSON.stringify({ CaseId: id }));
    public static getResubmissionReasons = () => RNSAPI.rpcRequest("/Resubmissions/GetReasons", JSON.stringify({}));
    public static createResubmission = (resubmission: any) => RNSAPI.rpcRequest("/Resubmissions/Create", ko.toJSON(resubmission));
    public static updateResubmission = (resubmission: any) => RNSAPI.rpcRequest("/Resubmissions/Edit", ko.toJSON(resubmission));
    public static deleteResubmission = (ID: string) => RNSAPI.rpcRequest("/Resubmissions/Delete", JSON.stringify({ ID: ID }));
    public static completeResubmission = (ID: string) => RNSAPI.rpcRequest("/Resubmissions/Complete", JSON.stringify({ ID: ID }));
    public static calculateResubmission = (calc: CalculateResubmission) => RNSAPI.rpcRequest("/Resubmissions/Calculate", ko.toJSON(calc));

    public static suggestCaseId = () => RNSAPI.rpcRequest("/Cases/SuggestId", JSON.stringify({}));
    public static updateCaseIdSuggestion = (recordId: string) => RNSAPI.rpcRequest("/Cases/UpdateSuggestion", JSON.stringify({ ID: recordId }));

    public static getSachbearbeiter = () => RNSAPI.rpcRequest("/Clerks/Get", JSON.stringify({}));

    public static getReferate = () => RNSAPI.rpcRequest("/Units/Get", JSON.stringify({}));

    // public static getBriefkopf = () => RNSAPI.rpcRequest("/Texts/GetHeader", JSON.stringify({}));
    public static getTextHeader = (TextHeaderName: string) => RNSAPI.rpcRequest("/Texts/GetTextHeaderData", JSON.stringify({ TextHeaderName: TextHeaderName}));

    //PostCodes
    public static getPostcode = (Postcode: string) => RNSAPI.rpcRequest("/Postcode/Postcode", JSON.stringify({ Postcode: Postcode}));
    public static getPostcodes = (Postcode: string) => RNSAPI.rpcRequest("/Postcode/Postcodes", JSON.stringify({ Postcode: Postcode}));
    //public static addReferat = (newUnit: any) => RNSAPI.rpcRequest("/Units/Add", ko.toJSON(newUnit));
    public static addReferat = (pId: string, pName: string) => {
        let data = JSON.stringify({
            Id: pId,
            Name: pName
        });
        return RNSAPI.rpcRequest("/Units/Add", data);
    }

    public static getCases = () => RNSAPI.rpcRequest("/Cases/Search", JSON.stringify({ query: "" }));
    public static getCaseByCaseId = (caseId: string) => RNSAPI.rpcRequest("/Cases/GetCase", JSON.stringify({ Id: caseId }));
    public static createCase = (newCase: any) => RNSAPI.rpcRequest("/Cases/Create", ko.toJSON(newCase));

    public static getLastCases = (amount: any) => RNSAPI.rpcRequest("/Cases/LastCases", JSON.stringify({ "Amount": amount }));

    public static updateCase = (newCase: any) => RNSAPI.rpcRequest("/Cases/Update", ko.toJSON(newCase));

    public static getCaseEntry = (id: string) => RNSAPI.rpcRequest("/ECases/ForId", JSON.stringify({ RecordId: id }));
    public static getCaseEntriesForId = (caseId: string) => RNSAPI.rpcRequest("/ECases/ForCase", JSON.stringify({ CaseId: caseId }));
    public static getCaseEntriesForWorkFlow = (workflow: string) => RNSAPI.rpcRequest("/ECases/ForWorkflowMark", JSON.stringify({ WorkflowMark: workflow }));
    public static getCaseEntriesForFilter = (filter: Filter): any => RNSAPI.rpcRequest("/ECases/ForFilter", JSON.stringify(filter));

    public static createCaseEntry = (entry: any) => RNSAPI.rpcRequest("/ECases/Insert", ko.toJSON(entry));
    public static createCaseFileEntry = (entry: FormData) => RNSAPI.rpcUploadRequest("/ECases/FileInsert2", entry);

    

    public static updateCaseEntryDocument = (id: string, document: string) => RNSAPI.rpcRequest("/ECases/Update", JSON.stringify({ ECaseId: id, DocumentData: document }));
    public static updateCaseEntry = (entry: any) => RNSAPI.rpcRequest("/ECases/Set", ko.toJSON(entry));
    public static setCaseEntryWorkflowMark = (recordId: string, mark: string) => RNSAPI.rpcRequest("/ECases/SetWorkFlowMark", ko.toJSON({ RecordId: recordId, WorkFlowMark: mark }));

    public static getAllTagTypes = () => RNSAPI.rpcRequest("/Tags/GetAll", JSON.stringify({}));
    public static deleteTagType = (name: string) => RNSAPI.rpcRequest("/Tags/Delete", JSON.stringify({ Name: name }));
    public static getTagTypeByName = (name: string) => RNSAPI.rpcRequest("/Tags/Get", JSON.stringify({ Name: name }));
    public static createTagType = (tagType: any) => RNSAPI.rpcRequest("/Tags/Add", ko.toJSON(tagType));
    public static updateTagType = (tagType: any) => RNSAPI.rpcRequest("/Tags/Update", ko.toJSON(tagType));
    public static getTagsForECaseRecordId = (id: string) => RNSAPI.rpcRequest("/Tags/GetForRecord", ko.toJSON({ Name: id }));
    public static setTagsForECaseRecordId = (tags: Array<string>, id: string) => RNSAPI.rpcRequest("/Tags/SetTags", ko.toJSON({ DatUhr: id, TagNames: tags }));

    public static getUserInfo = () => RNSAPI.rpcRequest("/LawFirm/GetStammdaten", JSON.stringify({}));
    // Updating
    public static updateUserInfo = (updateData: any) => RNSAPI.rpcRequest("/LawFirm/UpdateStammdaten", ko.toJSON(updateData));

    public static updateUserData = (data: any) => RNSAPI.rpcRequest("/LawFirm/UpdateUserData", ko.toJSON(data));

    public static getAllViews = () => RNSAPI.rpcRequest("/Views/GetAll", JSON.stringify({}));
    public static getViewByName = (name: string) => RNSAPI.rpcRequest("/Views/Get", JSON.stringify({ Name: name }));
    public static deleteView = (name: string) => RNSAPI.rpcRequest("/Views/Delete", JSON.stringify({ Name: name }));
    public static createView = (view: any) => RNSAPI.rpcRequest("/Views/Add", ko.toJSON(view));
    public static updateView = (view: any) => RNSAPI.rpcRequest("/Views/Update", ko.toJSON(view));

    //RVG APIS
    public static rvgGetAll = (version: string) => RNSAPI.rpcRequest("/rvg/getall", JSON.stringify({ Version: version }));
    public static rvgSearch = (searchObject: any) => RNSAPI.rpcRequest("/rvg/search", JSON.stringify(searchObject));
    public static rvgCalculate = (calculateObject: any) => RNSAPI.rpcRequest("/rvg/calculate", JSON.stringify(calculateObject));
    public static rvgAddEntry = (caseid: any, rechnungsnummer: string, adressatSuchbegriff: string, adressatTyp: string, entryObject: any, version: string, department: string) => RNSAPI.rpcRequest("/rvg/entry",
        JSON.stringify({
            CaseID: caseid,
            Rechnungsnummer: rechnungsnummer,
            AdressatSuchbegriff: adressatSuchbegriff,
            AdressatTyp: adressatTyp,
            FinishInvoice: true,
            RvgEntries: entryObject,
            Version: version,
            Department: department
        }));
    public static rvgUpdateEntry = (caseid: any, rechnungsnummer: string, adressatSuchbegriff: string, adressatTyp: string, entryObject: any, version: string) => RNSAPI.rpcRequest("/rvg/UpdateEntry",
        JSON.stringify({
            CaseID: caseid,
            Rechnungsnummer: rechnungsnummer,
            AdressatSuchbegriff: adressatSuchbegriff,
            AdressatTyp: adressatTyp,
            FinishInvoice: true,
            RvgEntries: entryObject,
            Version: version
        }));
    public static rvgLoadEntries = (rechNo: string) => RNSAPI.rpcRequest("/RVG/LoadEntries", JSON.stringify({ RechNr: rechNo }));
    public static rvgAbgleich = () => RNSAPI.rpcRequest("/rvg/abgleich", JSON.stringify({}));

    //MARK: - Dunning APIS
    public static addReminder = (reminderObject) => RNSAPI.rpcRequest("/Reminder/Add", JSON.stringify(reminderObject));
    public static getRemindersByStichtag = (date: string) => RNSAPI.rpcRequest("/Reminder/GetRemindersByStichtag", JSON.stringify({ Stichtag: date }));
    public static getRemindersByAdressat = (address: string) => RNSAPI.rpcRequest("/Reminder/GetRemindersByAdressat", JSON.stringify({ Adressat: address }));
    public static getRemindersByCaseId = (caseId: string) => RNSAPI.rpcRequest("/Reminder/GetRemindersByCaseId", JSON.stringify({ CaseId: caseId }));
    public static deleteReminder = (datUhr: string) => RNSAPI.rpcRequest("/Reminder/Delete", JSON.stringify({ DatUhr: datUhr }));
    public static updateReminder = (reminderObject) => RNSAPI.rpcRequest("/Reminder/Update", JSON.stringify(reminderObject));

    //MARK: - Rechnungsausgangsbuch APIS
    public static addAccounting = (accountingObject) => RNSAPI.rpcRequest("/Accounting/Add", JSON.stringify(accountingObject));
    public static getRechnungsausgangsbuchByCaseId = (caseId: String) => RNSAPI.rpcRequest("/Accounting/GetRechnungsausgangsbuchByCaseId", JSON.stringify({ CaseId: caseId }));
    public static getRechnungsausgangsbuchByRange = (startDate: string, endDate: string) => RNSAPI.rpcRequest("/Accounting/GetRechnungsausgangsbuchByRange",
        JSON.stringify({ StartDate: startDate, EndDate: endDate }));
    public static getRechnungsausgangsbuchByRechnungsnummer = (rechnungsnummer: string) => RNSAPI.rpcRequest("/Accounting/GetRechnungsausgangsbuchByRechnungsnummer",
        JSON.stringify({ Rechnungsnummer: rechnungsnummer }));

    //MARK: - RVG SuggestId APIS
    public static rvgSuggestId = () => RNSAPI.rpcRequest("/rvg/SuggestId", JSON.stringify({}));

    //MARK: - Aktenkonoto APIS
    public static addAktenkonto = (aktenkontoObject) => RNSAPI.rpcRequest("/Aktenkonto/Add", ko.toJSON(aktenkontoObject));
    public static updateAktenkonto = (aktenkontoObject) => RNSAPI.rpcRequest("/Aktenkonto/Update", ko.toJSON(aktenkontoObject));
    public static deleteAktenkonto = (datUhr: string) => RNSAPI.rpcRequest("/Aktenkonto/Delete", JSON.stringify({ DatUhr: datUhr }));
    public static getAktenkontoByCaseID = (caseId: string) => RNSAPI.rpcRequest("/Aktenkonto/GetAktenkontoByCaseID", JSON.stringify({ CaseId: caseId }));

    public static generateCaseEntryText = (CaseId: string, TemplateName: string, AddressType: string, AddressKeyword: string, WorkflowMark: string, DictationMark: string, TextName: string = "") => {
        let scopes = {
            "mandant": "01",
            "gegner": "02",
            "sonstige": "03"
        };

        let data = {
            CaseId: CaseId,
            TemplateName: TemplateName,
            TextName: TextName,
            AddressType: scopes[AddressType],
            AddressKeyword: AddressKeyword,
            WorkflowMark: WorkflowMark,
            DictationMark: DictationMark
        }

        return RNSAPI.rpcRequest("/Texts/generate", JSON.stringify(data));
    }

    public static generateDocument = (document: GenerateDocument) => RNSAPI.rpcRequest("/Text/Generate", JSON.stringify(document), "POST");
    public static getDocumentTemplates = () => RNSAPI.rpcRequest("/Text/Templates", null, "GET");
    public static getLetterHeads = () => RNSAPI.rpcRequest("/Text/LetterHeads", null, "GET");
    public static uploadLetterHead = (data: any) => RNSAPI.rpcUploadRequest("/Text/UploadLetterHead", data);
    public static deleteLetterHead = (letterHead: number) => RNSAPI.rpcRequest("/Text/LetterHead", JSON.stringify({ letterHeadId: letterHead }), "DELETE");
    public static getLetterHead = (letterHead: number) => RNSAPI.rpcRequest("/Text/LetterHead?letterHeadId="+ letterHead, null, "GET");
    public static changeLetterHead = (letterHead: number) => RNSAPI.rpcRequest("/Text/Standard", JSON.stringify({ letterHeadId: letterHead}), "POST"); //Prüfen

    public static loadDocumentEditorDocumentById = (docId: string) => RNSAPI.rpcRequest("/DocumentEditor/Import", JSON.stringify({ DocumentId: docId }), "POST");
    public static saveDocument = (document: SaveDocument) => RNSAPI.rpcRequest("/DocumentEditor/Export", JSON.stringify(document), "POST");


    public static getDocumentData = (eCaseId: string) => RNSAPI.rpcRequest("/Documents/Get", JSON.stringify({ ECaseRecordId: eCaseId }));
    public static getDocumentDataByName = (eCaseName: string, eCaseDate: Date) => RNSAPI.rpcRequest("/Documents/GetByName", JSON.stringify({ ECaseName: eCaseName, ECaseDate: eCaseDate }));
    public static deleteRecord = (eCaseId: string) => RNSAPI.rpcRequest("/ECases/Delete", JSON.stringify({ RecordId: eCaseId }));

    public static getTemplates = () => RNSAPI.rpcRequest("/Texts/GetTemplates", JSON.stringify({}));

    public static getAllMailAddresses = () => RNSAPI.rpcRequest("/Addresses/GetMails", "", "GET");

    public static createPerson = (person: any, handler: string) => RNSAPI.rpcRequest(`/Addresses/Add${handler}`, ko.toJSON(person));
    public static updatePerson = (person: any, type: string) => RNSAPI.rpcRequest(`/Addresses/Update${type}`, ko.toJSON(person));

    public static deletePerson(keyword: string, type: string): Promise<ReturnWrapper<any>> {
        let scope = RNSAPI.mapTypeToScope[type.toLowerCase()];
        return RNSAPI.rpcRequest(`/Addresses/Delete`, JSON.stringify({ PersonKeyword: keyword, Scope: scope }));
    }

    public static checkAssignment(keyword: string, type: string): Promise<ReturnWrapper<any>> {
        let scope = RNSAPI.mapTypeToScope[type.toLowerCase()];
        return RNSAPI.rpcRequest(`/Addresses/CheckAssignment`, JSON.stringify({ PersonKeyword: keyword, Scope: scope }));
    }

    public static joinPerson(personKeyword: string, caseId: string, handler: string): Promise<ReturnWrapper<any>> {
        let data = JSON.stringify({
            CaseId: caseId,
            PersonKeyword: personKeyword,
            Scope: RNSAPI.mapTypeToScope[handler.toLowerCase()]
        });
        return RNSAPI.rpcRequest(`/Addresses/Join`, data);
    }

    public static unjoinPerson(personKeyword: string, caseId: string, handler: string): Promise<ReturnWrapper<void>> {
        let data = JSON.stringify({
            CaseId: caseId,
            PersonKeyword: personKeyword,
            Scope: RNSAPI.mapTypeToScope[handler.toLowerCase()]
        });
        return RNSAPI.rpcRequest(`/Addresses/Unjoin`, data);
    }

    public static getPersonForKeyword(keyword: string, type: string): Promise<ReturnWrapper<any>> {
        let data = JSON.stringify({
            Scope: RNSAPI.mapTypeToScope[type.toLowerCase()],
            SearchValue: keyword
        });
        return RNSAPI.rpcRequest("/Addresses/ExactSearch", data);
    }

    public static getPersonsForCaseId = (caseId: string) => RNSAPI.rpcRequest("/Addresses/Search", JSON.stringify({ scope: 'bycaseid', searchValue: caseId }))

    public static getPersonsForScope(type: string): Promise<ReturnWrapper<any>> {
        let data = JSON.stringify({
            Scope: RNSAPI.mapTypeToScope[type.toLowerCase()],
            SearchValue: ""
        });
        return RNSAPI.rpcRequest("/Addresses/Search", data);
    }

    public static checkCollision = (lastName: string) => RNSAPI.rpcRequest("/Addresses/CheckCollision", JSON.stringify({ searchValue: lastName }))

    public static getTableDump = (dbNumber: number) => RNSAPI.rpcRequest("/Debug/DbTableDump", JSON.stringify({ DbNumber: dbNumber }))

    public static getNotes = () => RNSAPI.rpcRequest("/UserNote/Get", JSON.stringify({}));
    public static getNote = (id: string) => RNSAPI.rpcRequest("/UserNote/GetNote", JSON.stringify({ ID: id }));
    public static createNote = (note: Note) => RNSAPI.rpcRequest("/UserNote/Add", JSON.stringify(note));
    public static updateNote = (note: Note) => RNSAPI.rpcRequest("/UserNote/Update", JSON.stringify(note));
    public static deleteNote = (id: string) => RNSAPI.rpcRequest("/UserNote/Delete", JSON.stringify({ ID: id }));

    public static gettests = () => RNSAPI.rpcRequest("/UserNote/Get", JSON.stringify({}));
    public static gettest = (id: string) => RNSAPI.rpcRequest("/UserNote/GetNote", JSON.stringify({ ID: id }));
    public static createtest = (note: Note) => RNSAPI.rpcRequest("/UserNote/Add", JSON.stringify(note));
    public static updatetest = (note: Note) => RNSAPI.rpcRequest("/UserNote/Update", JSON.stringify(note));
    public static deletetest = (id: string) => RNSAPI.rpcRequest("/UserNote/Delete", JSON.stringify({ ID: id }));

    public static markDictationAsDone = (recordId: string) => RNSAPI.rpcRequest("/ECases/markDictationAsDone", JSON.stringify({ RecordId: recordId }));
    public static addDictate = (dto: any) => RNSAPI.rpcRequest("/Dictates/AddDictate", JSON.stringify(dto));
    public static updateDictate = (dto: any) => RNSAPI.rpcRequest("/Dictates/UpdateDictate", JSON.stringify(dto));
    public static getDictateById = (id: string) => RNSAPI.rpcRequest("/Dictates/GetDictate", JSON.stringify({ ID: id }));
    public static getAudioById = (id: string) => RNSAPI.rpcRequest("/Dictates/GetAudio", JSON.stringify({ ID: id }));


    public static deleteReferat = (id: string) => RNSAPI.rpcRequest("/Units/Delete", JSON.stringify({ Id: id }))
    public static updateReferat = (id: string, name: string) => RNSAPI.rpcRequest("/Units/update", JSON.stringify({ Id: id, Name: name }))

    public static addFrist = (fristData: any) => RNSAPI.rpcRequest("/Deadlines/AddReason", ko.toJSON(fristData));
    public static updateFrist = (updateData: any) => RNSAPI.rpcRequest("/Deadlines/UpdateReason", ko.toJSON(updateData));
    public static deleteFrist = (DeadlineNr: string) => RNSAPI.rpcRequest("/Deadlines//DeleteReason", JSON.stringify({ DeadlineNumber: DeadlineNr }));

    public static addWeider = (weiderData: any) => RNSAPI.rpcRequest("/Resubmissions/AddReason", ko.toJSON(weiderData));
    public static updateWeider = (updateData: any) => RNSAPI.rpcRequest("/Resubmissions/updateReason", ko.toJSON(updateData));
    public static deleteWeider = (DeadlineNr: string) => RNSAPI.rpcRequest("/Resubmissions/DeleteReason", JSON.stringify({ DeadlineNumber: DeadlineNr }));

    public static getRechtsformen = () => RNSAPI.rpcRequest("/LegalForms/Get", JSON.stringify({}));
    public static addRechtsformen = (addData: any) => RNSAPI.rpcRequest("/LegalForms/Add", ko.toJSON(addData));
    public static updateRechtsformen = (updateData: any) => RNSAPI.rpcRequest("/LegalForms/Update", ko.toJSON(updateData));
    public static deleteRechtsformen = (deleteData: string) => RNSAPI.rpcRequest("/LegalForms/Delete", JSON.stringify({ NameID: deleteData }));

    
    public static moveMailToCase = (mailData: MoveMailToCase) => RNSAPI.rpcRequest("/Zpe/MoveMailToCase", ko.toJSON(mailData), "POST");
    public static moveDocToCase = (docData: MoveDocToCase) => RNSAPI.rpcRequest("/ScanPool/MoveScanToCase", ko.toJSON(docData), "POST");

    
    public static deleteStampById = (stampId: string) => RNSAPI.rpcRequest("/Stamps/DeleteStamp", JSON.stringify({ ID: stampId }));
    public static getStampsByECaseId = (recordId: string) => RNSAPI.rpcRequest("/Stamps/GetStamps", JSON.stringify({ ID: recordId }));
    public static addStamp = (stampText: string, stampType: string, recordId: string) => {
        let obj = {
            Text: stampText,
            Type: stampType,
            DatUhr: recordId
        };
        return RNSAPI.rpcRequest("/Stamps/AddStamp", JSON.stringify(obj));
    }
    public static getStampTemplates = () => RNSAPI.rpcRequest("/Stamps/GetTemplates", JSON.stringify({}));

    public static addOrUpdateTree = (dto: TreeDto) => RNSAPI.rpcRequest("/Taxonomie/AddOrUpdateTree", ko.toJSON(dto));
    public static getTreesByType = (type: TreeType) => RNSAPI.rpcRequest("/Taxonomie/GetTreesByType", JSON.stringify({ "Type": type }));
    public static getTreeByTypeAndId = (type: TreeType, id: string) => RNSAPI.rpcRequest("/Taxonomie/GetTree", JSON.stringify({ "Type": type, TreeId: id }));
    public static deleteTreeByTypeAndId = (type: TreeType, id: string) => RNSAPI.rpcRequest("/Taxonomie/DeleteTree", JSON.stringify({ "Type": type, TreeId: id }));
    public static getStrategies = () => RNSAPI.rpcRequest("/Taxonomie/GetStrategies", JSON.stringify({}));
    public static getTaxonomies = () => RNSAPI.rpcRequest("/Taxonomie/GetTaxonomies", JSON.stringify({}));
    public static getTaxonomieById = (id: string) => RNSAPI.rpcRequest("/Taxonomie/GetTaxonomie", JSON.stringify({ ID: id }));
    public static addTaxonomie = (taxon: any) => RNSAPI.rpcRequest("/Taxonomie/AddTaxon", ko.toJSON(taxon));
    public static updateTaxonomie = (taxon: any) => RNSAPI.rpcRequest("/Taxonomie/UpdateTaxon", ko.toJSON(taxon));
    public static deleteTaxonomieById = (id: string) => RNSAPI.rpcRequest("/Taxonomie/DeleteTaxon", JSON.stringify({ ID: id }));
    public static joinTaxonomie = (strategy: StrategyDto) => RNSAPI.rpcRequest("/Taxonomie/JoinTaxonomie", ko.toJSON(strategy));
    public static unjoinTaxonomie = (id: string) => RNSAPI.rpcRequest("/Taxonomie/UnJoinTaxonomie", JSON.stringify({ StrategyId: id }));

    public static createExtendedCase = (eCase: any) => RNSAPI.rpcRequest("/ExtendedData/CreateCase", ko.toJSON(eCase));
    public static getExtendedCase = (eCase: string) => RNSAPI.rpcRequest("/ExtendedData/GetCase", JSON.stringify({ "id": eCase }));
    public static saveExtendedCase = (eCase: any) => RNSAPI.rpcRequest("/ExtendedData/UpdateCase", ko.toJSON(eCase));

    public static saveLawFirmAbonnementData = (data: any) => RNSAPI.rpcRequest("/LawFirm/UpdateAbonnement", ko.toJSON(data));
    public static getLawFirmAbonnementData = () => RNSAPI.rpcRequest("/LawFirm/GetAbonnement", JSON.stringify({}));

    public static createHistoryEntry = (registernummer: any, aktion: any) => RNSAPI.rpcRequest("/History/AddEntry", JSON.stringify({ "Case": registernummer, "Aktion": aktion }));
    public static getLawFirmUsers = () => RNSAPI.rpcRequest("/LawFirm/GetUsers", JSON.stringify({}));
    public static createLawFirmUser = (entryObject: any) => RNSAPI.rpcRequest("/LawFirm/CreateUser", ko.toJSON(entryObject));
    public static getLawFirmUser = (user: any) => RNSAPI.rpcRequest("/LawFirm/GetUser", JSON.stringify({ "Benutzer": user }));
    public static updateLawFirmUser = (entryObject: any) => RNSAPI.rpcRequest("/LawFirm/UpdateUser", ko.toJSON(entryObject));
    public static CreateLawFirm = (entryObject: any) => RNSAPI.rpcRequest("/LawFirms/Create", ko.toJSON(entryObject));

    public static updateAccount = (data: any) => RNSAPI.rpcRequest("/Account/UpdateUser", ko.toJSON(data));

    public static deleteLawFirmUser = (userId: string) => RNSAPI.rpcRequest("/LawFirm/DeleteUser", JSON.stringify({ "UserId": userId }));
    public static lockLawFirmUser = (userId: string) => RNSAPI.rpcRequest("/LawFirm/LockUser", JSON.stringify({ "UserId": userId }));
    public static unlockLawFirmUser = (userId: string) => RNSAPI.rpcRequest("/LawFirm/UnlockUser", JSON.stringify({ "UserId": userId }));

    public static getReminders = () => RNSAPI.rpcRequest("/Dunning/GetReminders", JSON.stringify({}));

    // This is for debugging purpose, set false on production
    public static DEBUG_Mode = false;

    //This data is not hold in a FATS database on the server
    //Later when there is SQL support all data should be migrated to SQL
    private static getUserData = () => RNSAPI.rpcRequest("/UserData/get", "") as Promise<ReturnWrapper<UserDataWrapper>>;
    private static setUserData = (data: UserData) => RNSAPI.rpcRequest("/UserData/set", JSON.stringify(new UserDataWrapper(data)));

    public static sendSmsRecoverCode = (user: any) => RNSAPI.rpcRequest("/Lawfirm/SendSMSRecoverCode", ko.toJSON(user));
    public static verifySMSRecoverCode = (user: any) => RNSAPI.rpcRequest("/Lawfirm/VerifySMSRecoverCode", ko.toJSON(user));
    public static randomSecurity = (user: any) => RNSAPI.rpcRequest("/Lawfirm/GetRandomUserSecurityQuestions ", ko.toJSON(user));
    public static verifySecurityQuestions = (user: any) => RNSAPI.rpcRequest("/Lawfirm/VerifySecurityQuestions", ko.toJSON(user));

    public static sessionLogin = () => RNSAPI.rpcRequest("/Session/Login", "");
    public static sessionLogout = () => RNSAPI.rpcRequest("/Session/Logout", "");

    public static getRecherche = (user: any) => RNSAPI.rpcRequest("/Lawfirm/GetRecherche", JSON.stringify({ "Benutzer": user }));

    public static sendSmsActivationCode = (user: any) => RNSAPI.rpcRequest("/Lawfirm/SendSMSActivationCode", ko.toJSON(user));
    public static verifyActivationCode = (user: any) => RNSAPI.rpcRequest("/Lawfirm/VerifyActivationCode", ko.toJSON(user));

    public static getHourly = (user: any) => RNSAPI.rpcRequest("/Lawfirm/GetHourly", JSON.stringify({ "Benutzer": user }));
    public static UpdateHourly = (HourlyValue: Number) => RNSAPI.rpcRequest("/Lawfirm/UpdateHourly", JSON.stringify({ "Hourly": HourlyValue }));


    public static createManualTimeMonitoring = (timeMonitoring: CreateManualTimeMonitoring) => RNSAPI.rpcRequest("/TimeMonitoring/Create", ko.toJSON(timeMonitoring), "POST");

    public static startTimeMonitoring = (id: any) => RNSAPI.rpcRequest("/Lawfirm/CreateTimeMonitoring", JSON.stringify({ "Query": ko.toJSON(id) }));
    public static updateTimeMonitoring = (id: any) => RNSAPI.rpcRequest("/Lawfirm/UpdateTimeMonitoring", JSON.stringify({ "ID": ko.toJSON(id) }));
    public static getTimeMonitorings = (transmitted: Number) => RNSAPI.rpcRequest("/Lawfirm/GetTimeMonitoring", JSON.stringify({ "Transmitted": ko.toJSON(transmitted) }));
    public static SaveTimeMonitoring = (TE: any) => RNSAPI.rpcRequest("/Lawfirm/SaveTimeMonitoring", JSON.stringify(TE));
    public static DeleteTimeMonitoring = (id: any) => RNSAPI.rpcRequest("/Lawfirm/DeleteTimeMonitoring", JSON.stringify({ "ID": ko.toJSON(id) }));
    public static BillTimeMonitoring = (TE: any) => RNSAPI.rpcRequest("/Lawfirm/BillTimeMonitoring", JSON.stringify({ "TimeMonitorings": TE }));

    public static GetTutorial = () => RNSAPI.rpcRequest("/Lawfirm/GetTutorial", "");
    public static UpdateTutorial = (Page: Number) => RNSAPI.rpcRequest("/Lawfirm/UpdateTutorial", JSON.stringify({ "Tutorial": ko.toJSON(Page) }));
    public static GetTemplate = (Type: String) => RNSAPI.rpcRequest("/ExtendedData/GetTemplate", JSON.stringify({ "Id": Type }));

    public static GetStatistic = (SB: String, StartFilter: String, EndFilter: String, Type: String) => RNSAPI.rpcRequest("/Cases/GetStatistics", JSON.stringify({ "SB": SB, "StartFilter": StartFilter, "EndFilter": EndFilter, "Type": Type }));

    public static GetBank = (BLZ: string) => RNSAPI.rpcRequest("/Addresses/GetBank", JSON.stringify({ "SearchValue": BLZ })); 
    public static GetStateByGerId = (GerId: string) => RNSAPI.rpcRequest("/Addresses/GetStateByGerId", JSON.stringify({ "GerId": GerId })); 

    public static checkAssignements = () => RNSAPI.rpcRequest("/Lawfirm/Check", JSON.stringify({}));
    public static getAssignements = () => RNSAPI.rpcRequest("/Lawfirm/GetAssignments", JSON.stringify({}));
    public static assignLocation = (LawfirmID: string, UserID: string, Password: string) => RNSAPI.rpcRequest("/Lawfirm/Assign", JSON.stringify({ "LawfirmID": LawfirmID, "UserID": UserID, "Password": Password }));
    public static removeLocation = (LawfirmID: string, UserID: string) => RNSAPI.rpcRequest("/Lawfirm/Remove", JSON.stringify({ "LawfirmID": LawfirmID, "UserID": UserID }));

    public static addOutlook = (EWSURL: string, UserLogin: string, UserPassword: string) => RNSAPI.rpcRequest("/Outlook/Add", JSON.stringify({ "EWSURL": EWSURL, "UserLogin": UserLogin, "UserPassword": UserPassword }));
    public static getOutlook = () => RNSAPI.rpcRequest("/Outlook/LoadAll", "{}");
    public static removeOutlook = (accountID: string) => RNSAPI.rpcRequest("/Outlook/RemoveAccount", JSON.stringify({ "AccountID": accountID }));
    public static notifyDeadline = (OutlookAccountID: string, deadline: any, notifySB = false) => RNSAPI.rpcRequest("/Outlook/Export", JSON.stringify({ OutlookAccountID: OutlookAccountID, Deadline: deadline, NotifySB: notifySB }));
    public static notifyAppointment = (OutlookAccountID: string, appointment: any) => RNSAPI.rpcRequest("/Outlook/RealExport", JSON.stringify({ OutlookAccountID: OutlookAccountID, Appointment: appointment }));
}

if (!localStorage["notes"]) localStorage["notes"] = JSON.stringify([]);
if (!localStorage["deadlines"]) localStorage["deadlines"] = JSON.stringify([]);
