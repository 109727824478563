import * as fs from 'fs';
import * as ko from "knockout";
import * as moment from 'moment';
import { RNSAPI } from "../../api";
import { Utils } from '../../utils';
import { MainViewModel } from "../../main";
import '../dialog/message';

class Person {
    type: string;
    keyword: string;
    personKeyword: string[];
    fullName: string;

    constructor(type: string, keyword: string, fullName: string, personKeyword?: string[]) {
        this.type = type;
        this.keyword = keyword;
        this.fullName = fullName;
        this.personKeyword = personKeyword;
    }

    getText() {
        return `${this.type.charAt(0).toUpperCase() + this.type.slice(1)}: ${this.keyword}`;
    }
}

class Users {
    name: string;
    shorthandSymbol: string;

    constructor(name: string, shorthandSymbol: string) {
        this.name = name;
        this.shorthandSymbol = shorthandSymbol;
    }
}

export class Stamp {
    Id: string | null;
    Text: string;
    Type: string;

    constructor(Text: string, Type: string, Id?: string) {
        this.Text = Text;
        this.Type = Type;
        if (Id) this.Id = Id;
    }
}

class PredefinedStamp {
    AnnoTyp: string
    Bezeichnung: string;
    Text: string
    Workflow: string
    Farbe: string
}

class Dokumente {
    caseEntries = ko.observableArray([])
    workflows = [
        {
            "Id": "PE",
            "Description": "Posteingang",
            "Followers": ["B", "RA"]
        }, {
            "Id": "RA",
            "Description": "Bearbeitung Rechtsanwalt",
            "Followers": ["B", "V"]
        }, {
            "Id": "B",
            "Description": "Bearbeitung Büro",
            "Followers": ["ZU", "RA"]
        }, {
            "Id": "ZU",
            "Description": "Unterschriftsmappe",
            "Followers": ["B", "V"]
        }, {
            "Id": "V",
            "Description": "Postausgang",
            "Followers": ["PA"]
        }, {
            "Id": "PA",
            "Description": "Gesendet",
            "Followers": ["E"]
        }
        , {
            "Id": "E",
            "Description": "Erledigt",
            "Followers": []
        }
    ]
    wfNames = {
        "PE": "Posteingang",
        "RA": "Bearbeitung Rechtsanwalt",
        "B": "Bearbeitung Büro",
        "ZU": "Unterschriftsmappe",
        "V": "Postausgang",
        "PA": "Gesendet",
        "E": "Erledigt",
    }
    tags = ko.observableArray([])
    textGenerationWorkflows = ko.observableArray([
        {
            name: "PE",
            text: "Posteingang"
        }, {
            name: "RA",
            text: "Bearbeitung Rechtsanwalt"
        }, {
            name: "B",
            text: "Bearbeitung Büro"
        }, {
            name: "ZU",
            text: "Unterschriftsmappe"
        }, {
            name: "V",
            text: "Postausgang"
        }, {
            name: "PA",
            text: "Gesendet"
        }, {
            name: "E",
            text: "Erledigt"
        }])
    persons = ko.observableArray();
    allPersons = ko.observableArray();
    allUsers = ko.observableArray();
    dirs = ko.observableArray([]);
    subDirs = ko.observableArray([]);
    files = ko.observableArray([]);
    caseId: string;
    documentColumnHeaders = ['Geändert', 'Dok-Dat', 'Textinhalt', 'Empfänger/Absender', 'Workflow', 'SB'];
    documentColumns = ['RecordDate', 'DocumentDate', 'Subject', 'Recipient', 'Workflow', 'LawyerId'];
    isDocumentsEmpty: ko.Computed<boolean>;
    currentViewer = ko.observable("pdf");
    url: ko.Observable<string> = ko.observable("");
    documentData = null;
    selectedElement = ko.observable(null);
    currentId = ko.observable(null);
    currentMimeType = ko.observable(null);
    currentDocumentDate = ko.observable(null);
    documentToEmail = ko.observable(null);
    emailAddress = ko.observable("");
    basicEntry = this.newBasicEntry();
    selectedWorkflowItem = ko.observable('');
    selectedTextGWorkflow = ko.observable('');
    selectedWorkflow = ko.observableArray();
    selectedTextUser = ko.observable('');
    selectedUserType = ko.observable('');
    person: ko.Observable<Person> = ko.observable<Person>(null);
    dictationMark = ko.observable("");
    workflowChanged;
    dirChange;
    subdirChange;
    templateChange;
    anreden = ko.observableArray(['Herr', 'Frau', 'Herr Dr.', 'Frau Dr.']);
    wopiToken = {
        "AccessToken": "",
        "AccessTokenTtl": 0
    }
    documentText = ko.observable('');
    infoMessage = ko.observable('');
    infoTitle = ko.observable('');
    modialAutoHide = ko.observable(false);
    errorMessage = ko.observable("");
    documentTemplateStructur = ko.observableArray();
    documentTemplates = ko.observableArray();
    templateCategories = ko.observableArray();
    selectedTemplateCategory = ko.observable("");
    selectedDocumentTemplate = ko.observable("");
    isGeneratingDocument = ko.observable<boolean>(false);
    downloadAfterGenerating = ko.observable<boolean>(false);
    changeTemplateCategory;

    async pickGeneric(title, keys, columns, data) {
        this.modalTitle(title);
        this.modalKeys(keys);
        this.modalColumns(columns);
        this.modalData(data);
    };

    modalHandleSelection = ko.observable();
    modalTitle = ko.observable("");
    modalKeys = ko.observableArray([]);
    modalColumns = ko.observableArray([]);
    modalData = ko.observableArray([]);

    newBasicEntry() {
        let obj = {
            Id: ko.observable(null),
            CaseId: ko.observable(""),
            CurrentCRTVersion: ko.observable(" "),
            DocType: ko.observable(0),
            Documents: ko.observableArray([]),
            LawyerId: ko.observable(RNSAPI.User() ? RNSAPI.User().username : "GR"),
            MimeType: ko.observable("application/pdf"),
            Note1: ko.observable(""),
            Recipient: ko.observable(""),
            Subject: ko.observable(""),
            imex: ko.observable("A"),
            isScanned: ko.observable("0"),
            isVerschluss: ko.observable(false),
            WorkflowId: ko.observable("")
        };

        return ko.observable(obj);
    };

    removeSelectedTag(tag) {
        let index = this.selectedWorkflow().indexOf(tag)
        if (index !== -1) {
            this.selectedWorkflow.splice(index, 1)
        }
    }

    async createDokumenEntryRef(docData: FormData, ending: string): Promise<void> {
        docData.append("CaseId", this.caseId);
        docData.append("OleType", ending.toUpperCase());
        docData.append("OLE2Type", ending.toUpperCase());
        docData.append("LawyerId", this.selectedTextUser());
        docData.append("Note1", "");
        docData.append("Recipient", this.person());
        docData.append("Subject", this.basicEntry().Subject());
        docData.append("WorkflowId", this.selectedWorkflowItem());

        await RNSAPI.createCaseFileEntry(docData).then((response) => {
            $('#uploadDocument').modal('hide');
            this.uploadDocument = false;
            this.loadEntries();
        }).catch((error) => {
            this.errorMessage("Die Datei konnte nicht hochgeladen werden.");
        });
    }

    resetUploadDocument() {
        this.basicEntry().Subject('');
        this.files([]);
        this.uploadDocument = false;
        $('#upload-file-info').text('')
    }

    async createEntry() {
        this.uploadDocument = true;
        $('#uploadDocument').modal('show');
        this.triggetTemplateChange();
        const tags: any = (await RNSAPI.getAllTagTypes()).Payload.Tags
        if (tags) {
            this.tags(tags)
        }
    }

    executeAction = (elem: any, handler: any) => {
        if (handler.selectable) {
            this.selectedElement(elem);
        }

        handler.action();
    }

    async postDokumentData() {
        let selectedFile = (document.getElementById('ownFile') as any).files[0];

        let formData = new FormData();
        if (selectedFile === undefined) {
            this.errorMessage("Bitte geben Sie eine Datei an.");
            return;
        } else {
            if (selectedFile.size / 1024 / 1024 > 10) {
                this.errorMessage("Die Ausgewählte Datei ist zu groß.");
                return;
            }
            else
            {
                if (this.basicEntry().Subject() && this.person() && this.selectedWorkflowItem()) {
                    formData.append("Document", selectedFile);
                    await this.createDokumenEntryRef(formData, selectedFile.name.split('.').pop());
                    this.resetUploadDocument();
                } else {
                    this.errorMessage("Es wurden nicht alle Pflichtfelder ausgefüllt.");
                }
            }

        }
    }

    createBlancoText(): void {
        MainViewModel.RoutingTable.showDocumentEditorView({ caseId: this.caseId });
    }
    
    async createText() {
        $("#createTextDocument").modal('show');
        this.resetCreateTextModalForm();

        await RNSAPI.getDocumentTemplates().then((response) => {
            this.documentTemplateStructur(response as any);
        });

        this.templateCategories([]);
        for(let i=0; i<this.documentTemplateStructur().length; i++) {
            this.templateCategories.push({ CategoryName: this.documentTemplateStructur()[i]["CategoryName"] });
        }

        this.triggetTemplateChange();
    }


    allPersonsObject = [];

    private async getCasePersons(): Promise<void> {
        this.persons([]);
        this.allPersons([]);
        await RNSAPI.getPersonsForCaseId(this.caseId).then(async (response) => {
            const addresses = response.Payload.Addresses;
            await this.getPersonMandanten(addresses[0]);
            await this.getPersonGegner(addresses[1]);
            await this.getPersonSonstige(addresses[7]);
            await this.getPersonGerichte(addresses[6]);
            await this.getPersonBehoerde();
            await this.getPersonMandantHaftpflicht();
        });

        
        this.persons(this.allPersonsObject);
        this.allPersons(this.allPersonsObject);
        this.person(this.persons()[0]);
        this.triggetTemplateChange();
    }

    private async getPersonMandanten(data: any[]): Promise<void> {
        for(const obj of data) {
            if(obj.KrankenVersId || obj.RechtsschutzVersId) {
                await this.getVersicherungsData(obj);
            }
            const object = {
                keyword: obj.Keyword,
                fullName: `${obj.FirstName} ${obj.Name1} ${obj.Name2} ${obj.Name3}`,
                type: "Mandant"
            }
            this.allPersonsObject.push(object);
        }
    }

    private async getPersonGegner(data: any[]): Promise<void> {
        for(const obj of data) {
            const object = {
                keyword: obj.Keyword,
                fullName: `${obj.FirstName} ${obj.Name1} ${obj.Name2} ${obj.Name3}`,
                type: "Gegner"
            }
            this.allPersonsObject.push(object);
        }
    }

    private async getPersonSonstige(data: any[]): Promise<void> {
        for(const obj of data) {
            const object = {
                keyword: obj.Keyword,
                fullName: `${obj.FirstName} ${obj.Name1} ${obj.Name2} ${obj.Name3}`,
                type: "Sonstige"
            }
            this.allPersonsObject.push(object);
        }
    }

    private async getPersonGerichte(data: any[]): Promise<void> {
        for(const obj of data) {
            const object = {
                keyword: obj.Keyword,
                fullName: `${obj.Name1} ${obj.Name2} ${obj.Name3}`,
                type: "Gericht"
            }
            this.allPersonsObject.push(object);
        }
    }

    private async getPersonBehoerde(): Promise<void> {
        await RNSAPI.getCaseByCaseId(this.caseId).then((response) => {
            const data = response.Payload.Case;
            const object = {
                keyword: data.AuthorityId,
                fullName: `${data.AuthorityId}`,
                type: "Behörde"
            }
            this.allPersonsObject.push(object);
        });
    }

    private async getPersonMandantHaftpflicht(): Promise<void> {
        await RNSAPI.getCaseByCaseId(this.caseId).then((response) => {
            const data = response.Payload.Case;
            const object = {
                keyword: data.MandantHaftpflichtversicherungId,
                fullName: `${data.MandantHaftpflichtversicherungId}`,
                type: "Haftpflicht Mandant"
            }
            if(object.keyword !== "") {
                this.allPersonsObject.push(object);
            }
        });
    }

    private async getVersicherungsData(data: any): Promise<void> {

        if(data.KrankenVersId) {
            await RNSAPI.getPersonForKeyword(data.KrankenVersId, "versicherung").then((response) => {
                const ducplicated = this.allPersonsObject.find(x => x.type === "KV" && x.keyword === data.KrankenVersId);
                if(ducplicated) {
                    ducplicated.personKeyword.push(data.Keyword);
                } else {
                    if(response.Payload.Address) {
                        const obj1 = {
                            keyword: data.KrankenVersId,
                            fullName: response.Payload.Address.Name1,
                            type: "KV",
                            personKeyword: [data.Keyword]
                        }
                        this.allPersonsObject.push(obj1);
                    }
                    
                }
            });
        }

        if(data.RechtsschutzVersId) {
            await RNSAPI.getPersonForKeyword(data.RechtsschutzVersId, "versicherung").then((response) => {
                const ducplicated = this.allPersonsObject.find(x => x.type === "RS" && x.keyword === data.RechtsschutzVersId);
                if(ducplicated) {
                    ducplicated.personKeyword.push(data.Keyword);
                } else {
                    if(response.Payload.Address) {
                        const obj1 = {
                            keyword: data.RechtsschutzVersId,
                            fullName: response.Payload.Address.Name1,
                            type: "RS",
                            personKeyword: [data.Keyword]
                        }
                        this.allPersonsObject.push(obj1);
                    }
                    
                }
            
            });
        }

        // if(data.MandantHaftpflichtversicherungId) {
        //     await RNSAPI.getPersonForKeyword(data.MandantHaftpflichtversicherungId, "versicherung").then((response) => {
        //         const ducplicated = this.allPersonsObject.find(x => x.type === "Haftpflicht Mandant" && x.keyword === data.MandantHaftpflichtversicherungId);
        //         if(ducplicated) {
        //             ducplicated.personKeyword.push(data.Keyword);
        //         } else {
        //             const obj1 = {
        //                 keyword: data.RechtschutzVersId,
        //                 fullName: response.Payload.Address.Name1,
        //                 type: "Haftpflicht Mandant",
        //                 personKeyword: [data.Keyword]
        //             }
        //             this.allPersonsObject.push(obj1);
        //         }
        //     });
        // }
    }


    async getPersons() {
        await this.getCasePersons();
    }

    async getVersiBeh() {
        let versiBeh = [];
        let asf = (await RNSAPI.getCaseByCaseId(this.caseId)).Payload.Case;
        let behoerde = new Person("Behörde", asf.AuthorityId, asf.AuthorityId);
        if (behoerde.keyword)
            versiBeh.push(behoerde);
        let gegHafpfl = new Person("Haftpflicht Gegner", asf.GegnerHaftpflichtversicherungId, asf.GegnerHaftpflichtversicherungId);
        if (gegHafpfl.keyword)
            versiBeh.push(gegHafpfl);
        let manHafpfl = new Person("Haftpflicht Mandant", asf.MandantHaftpflichtversicherungId, asf.MandantHaftpflichtversicherungId);
        if (manHafpfl.keyword)
            versiBeh.push(manHafpfl);

        return versiBeh;
    }

    async getUsers() {
        let users = (await RNSAPI.getLawFirmUsers()).Payload.Users
        let us = [];
        users.map(u => {
            us.push(new Users(u.Name, u.Shorthandsymbol));
        });
        this.allUsers(us);
    }

    private getBeteiligteData(keyword: string): any {
        return ko.toJS(this.beteiligte()).filter(x => x.keyword === keyword)[0];
    }

    private getPersonsData(keyword: string): any {
        let data = ko.toJS(this.persons()).filter(x => x.keyword === keyword);
        if(data.length >= 1) {
            return data[0];
        } else {
            return keyword;
        }
    }

    private convertAddressType(type: string): string {
        switch(type) {
            case "Gericht":
                return "8";
            case "Mandant":
                return "2";
            case "Haftpflicht Mandant":
            case "KV":
                return "5";
            default: 
                return type;
        }
    }

    async generateDocument() {
        this.errorMessage("");
        if (!Utils.checkErrors(["documentText"], { documentText: this.documentText }, 'dokumente', [Utils.checkString])) return;
        if (!Utils.checkErrors(["selectedTextGWorkflow"], { selectedTextGWorkflow: this.selectedTextGWorkflow }, 'dokumente', [Utils.checkString])) return;
        if (this.selectedDocumentTemplate() === "" || this.selectedDocumentTemplate() === undefined) return;
        if(!this.person()) {
            this.errorMessage("Das Dokument kann durch fehlende Stammdaten nicht generiert werden.");
            this.isGeneratingDocument(false);
            return;
        }
        this.isGeneratingDocument(true);

        if (this.person().type === "Mandant") {
            this.person().type = "mandant";
        }
        else if (this.person().type === "Gegner") {
            this.person().type = "gegner";
        }
        else if (this.person().type === "Sonstige") {
            this.person().type = "sonstige";
        } 
        else if(this.person().type === "Gericht") {
            this.person().type = "gericht";
        } 
        else if(this.person().type === "Haftpflicht Mandant" || this.person().type === "KV" || this.person().type === "RS") {
            this.person().type = "5";
        }

        let obj = {
            CaseId: this.caseId,
            TemplateId: Number(this.selectedDocumentTemplate()), 
            AddressType: this.convertAddressType(this.getPersonsData(this.person()).type),
            AddressKeyword: this.getPersonsData(this.person()).keyword,
            UserId: this.selectedTextUser(),
            Workflowmark: this.selectedTextGWorkflow(),
            Dictationmark: this.dictationMark(),
            Description: this.documentText(),
            Additionals: []
        };

        if(this.beteiligter()) {
            if(this.getBeteiligteData(this.beteiligter()).type === "Mandant") {
                obj.Additionals.push({
                    AddressType: "2",
                    AddressKeyword: this.beteiligter()
                });
            }
        }

        await RNSAPI.generateDocument(obj).then(async (response) => {
            if(this.downloadAfterGenerating()) {
                await this.download(this.documentText(), response["DocumentId"]);
            }

            this.documentText("");
            this.dictationMark("");
            $('#createTextDocument').modal('hide');
            this.loadEntries();
            this.isGeneratingDocument(false);
           
        }).catch((error) => {
            alert("Fehler bei der Texterstellung.");
            this.isGeneratingDocument(false);
        });
    }

    selectedDocumentTemplateData = {} as any;

    private readonly versicherungTemplates = [
        "Schreiben an Rechtschutzversicherung",
        "Schreiben an Mandant Haftpflicht",
        "Schreiben an Rentenversicherung",
        "Schreiben an Krankenversicherung",
        "Schreiben an Kaskoversicherung"
    ];

    private readonly behoerdeTemplates = [
        "Schreiben an Verwaltungsbehörde"
    ];

    private readonly gerichtTemplates = [
        "Schreiben an Gericht",
        "Schreiben an Gericht I",
        "Schreiben an Gericht II",
        "Schreiben an Gericht III"
    ];

    private readonly sonstigeTemplates = [
        "Schreiben an Sonstige"
    ];

    beteiligte = ko.observableArray([]);
    beteiligter = ko.observable();


    changeAn(data, event): void {
        if(this.beteiligte().length > 0) {
            const keyword = ko.toJS(this.person());
            const filtered = ko.toJS(this.persons()).filter(x => x.keyword === keyword);
            if(filtered[0]) {
                let beteiligteObj = [];
                if(filtered[0].personKeyword) {
                    for(const obj of filtered[0].personKeyword) {
                        const mandantData = ko.toJS(this.beteiligte()).filter(x => x.keyword === obj);
                        if(mandantData[0]) {
                            beteiligteObj.push({
                                keyword: mandantData[0].keyword,
                                fullName: mandantData[0].fullName,
                                type: mandantData[0].type
                            });
                        }
                    }
                    this.beteiligte(beteiligteObj);
                }
                
            }
        }
    }

    changeDocumentTemplate(data, event): void {
        this.beteiligte([]);
        this.beteiligter(null);
        let targetValue;
        if(event) {
            targetValue = event.target.value;
            this.selectedDocumentTemplate(targetValue);
        } else {
            targetValue = this.selectedDocumentTemplate();
        }

        const templateData = ko.toJS(this.documentTemplates()).find(x => x.Id === Number(targetValue));

        if(templateData) {
            this.selectedDocumentTemplateData = { TemplateName: templateData.Description, Id: templateData.Id };
        }

        if(this.sonstigeTemplates.includes(this.selectedDocumentTemplateData.TemplateName)) {
            const persons = ko.toJS(this.allPersons());

            switch(this.selectedDocumentTemplateData.TemplateName) {
                case "Schreiben an Sonstige":
                    this.persons(persons.filter(x => x.type === "Sonstige"));
                break;
            }
        }


        if(this.behoerdeTemplates.includes(this.selectedDocumentTemplateData.TemplateName)) {
            const persons = ko.toJS(this.allPersons());
            const beteiligtePersons = ko.toJS(this.allPersons());

            switch(this.selectedDocumentTemplateData.TemplateName) {
                case "Schreiben an Verwaltungsbehörde":
                    this.persons(persons.filter(x => x.type === "Behörde"));
                    this.beteiligte(beteiligtePersons.filter(x => x.type === "Mandant"));
                    this.beteiligter(this.beteiligte()[0]);
                break;
            }
        }

        if(this.gerichtTemplates.includes(this.selectedDocumentTemplateData.TemplateName)) {
            const persons = ko.toJS(this.allPersons());

            switch(this.selectedDocumentTemplateData.TemplateName) {
                case "Schreiben an Gericht I":
                case "Schreiben an Gericht II":
                case "Schreiben an Gericht III":
                case "Schreiben an Gericht":
                    this.persons(persons.filter(x => x.type === "Gericht"));
                break;
            }
        }

        if(this.versicherungTemplates.includes(this.selectedDocumentTemplateData.TemplateName)) {
            const persons = ko.toJS(this.allPersons());
            const beteiligtePersons = ko.toJS(this.allPersons());

            switch(this.selectedDocumentTemplateData.TemplateName) {
                case "Schreiben an Krankenversicherung":
                    this.persons(persons.filter(x => x.type === "KV"));
                    if(ko.toJS(this.persons()).length > 0) {
                        let arr = [];
                        for(const data of persons) {
                            for(const bet of beteiligtePersons) {
                                if(bet.type !== "Mandant") continue;
                                if(!data.personKeyword) continue;
                                if(!data.personKeyword.includes(bet.keyword)) continue;
                                arr.push(bet);
                            }
                        }
                        this.beteiligte(arr);
                        this.beteiligter(this.beteiligte()[0]);
                    }
                break;
                case "Schreiben an Mandant Haftpflicht":
                    this.persons(persons.filter(x => x.type === "Haftpflicht Mandant"));
                    if(ko.toJS(this.persons()).length > 0) {
                        this.beteiligte(beteiligtePersons.filter(x => x.type === "Mandant"));
                        this.beteiligter(this.beteiligte()[0]);
                    }
                break;
                case "Schreiben an Rechtschutzversicherung":
                    this.persons(persons.filter(x => x.type === "RS"));
                    if(ko.toJS(this.persons()).length > 0) {
                        let arr = [];
                        for(const data of persons) {
                            for(const bet of beteiligtePersons) {
                                if(bet.type !== "Mandant") continue;
                                if(!data.personKeyword) continue;
                                if(!data.personKeyword.includes(bet.keyword)) continue;
                                arr.push(bet);
                            }
                        }
                        this.beteiligte(arr);
                        this.beteiligter(this.beteiligte()[0]);
                    }
                break;
                case "Schreiben an Rentenversicherung":
                case "Schreiben an Kaskoversicherung":
                    this.persons([]);
                    this.beteiligte([]);
                break;
            }
        }   
        
        this.person(this.persons()[0]);
        
    }


    predefinedStamps = ko.observableArray([]);
    currentStamps: ko.ObservableArray<Stamp> = ko.observableArray([])

    private async loadPredefinedStamps() {
        let templates = (await RNSAPI.getStampTemplates()).Payload.Templates;
        this.predefinedStamps(templates);
    }

    private async loadStamps(recordId: string) {
        if (recordId) {
            let stamps = (await RNSAPI.getStampsByECaseId(recordId)).Payload.Stamps;
            let stampsWithEditedDate = stamps.map(stamp => {
                stamp.CreationDate = (new Date(stamp.CreationDate)).toLocaleDateString();
                return stamp;
            });
            this.currentStamps(stampsWithEditedDate);
        }
    }

    createStampFromTemplate = async (stamp: PredefinedStamp) => {
        let result = await RNSAPI.addStamp(stamp.Text, "Regular", this.currentId());
        if (result.Type === "AddSuccessful") {
            this.updateStamps();
        } else {
            alert("Fehler beim Erstellen des Stempels.");
        }
    }

    public async updateStamps() {
        this.loadStamps(this.currentId());
    }

    deleteStamp = async (stampId: string) => {
        let result = await RNSAPI.deleteStampById(stampId);
        if (result.Type === "DeleteStampSuccessful") {
            this.updateStamps();
        } else {
            alert("Fehler beim Löschen des Stempels.");
        }
    }

    openaktenoverview = async () => {
        window.open("/#/cases")
    }

    async showPDF(recordId) {
        let doc = (await RNSAPI.getDocumentData(recordId)).Payload.Document;
        let { DocumentData } = doc;
        let blob = Utils.base64ToBlob(DocumentData, "application/pdf");
        var file = window.URL.createObjectURL(blob);
        $('#pdf-view').attr('src', file)
    }

    async showWorkflowModal(entryId: string, caseId: string) {
        let workflows = ko.toJS(this.workflows);
        this.pickGeneric("Workflow festlegen", ["Description"], ["Workflow"], workflows);
        this.modalHandleSelection((selectedObject) => {
            this.setWorkflow(entryId, selectedObject()["Id"])
        });
        $('#Dokumentemodal').modal('show');
    }

    async setWorkflow(entryId: string, mark: string) {
        await RNSAPI.setCaseEntryWorkflowMark(entryId, mark);
        this.loadEntries();
    }

    async pickClerkDelegation(entry) {
        let sachbearbeiter = (await RNSAPI.getSachbearbeiter()).Payload.Clerks.filter(s => s.Sachbearbeiter_ID.trim() !== "");
        this.pickGeneric("Dokument delegieren", ["Sachbearbeiter"], ["Sachbearbeiter"], sachbearbeiter);
        this.modalHandleSelection((selectedObject) => {
            this.setSbEntry(entry, (selectedObject()["Sachbearbeiter_ID"]));
        });
        $('#Dokumentemodal').modal('show');
    };

    async setSbEntry(entry, sb: string) {
        entry.LawyerId = sb;
        await RNSAPI.updateCaseEntry(entry)
        this.loadEntries();
    }

    async loadEntries() {
        try {

            let entries = (await RNSAPI.getCaseEntriesForId(this.caseId)).Payload.Records
                .filter((entry) => {
                    return entry.MimeType.indexOf("application/vnd.renostar.xmp") === -1;
                })
                .reverse()
                .map((entry) => {
                    entry.DocumentDate = moment(entry.LastChange || entry.DocumentDate || entry.RecordDate).format('DD.MM.YYYY');
                    entry.RecordDate = moment(entry.RecordDate).format('DD.MM.YYYY');
                    entry.moreActionHandlers = [
                        {
                            'icon': 'envelope',
                            'name': 'Versenden',
                            'action': () => {
                                this.currentId(entry.Id);
                                this.currentViewer('')
                                this.sendMail(entry);
                            }
                        },
                        {
                            'icon': 'trash-alt',
                            'name': 'Löschen',
                            'action': () => {
                                this.remove(entry.Id);
                            }
                        }
                    ]
                    if (entry.Subject === "")
                        entry.Subject = entry.DocDBIds[0];
                    entry.actionHandlers = [
                        {
                            'icon': 'eye',
                            'name': 'Ansehen',
                            'action': () => {
                                this.currentDocumentDate(null);
                                this.currentViewer("");
                                this.currentMimeType(entry.MimeType.toLowerCase());
                                if (entry.Id === '') {
                                    entry.Id = entry.DocDBIds[0];
                                    this.currentDocumentDate(entry.DocumentDate);
                                }
                                this.currentId(entry.Id);

                                if (entry.MimeType.toLowerCase().indexOf("pdf") !== -1) {
                                    this.currentViewer("pdf");
                                    this.updateStamps();
                                    this.showPDF(entry.Id);
                                } else if (entry.MimeType.toLowerCase().indexOf("rfc822") !== -1) {
                                    this.currentViewer("mail");
                                    this.updateStamps();
                                } else if (entry.MimeType.toLowerCase().indexOf("audio/ogg") !== -1) {
                                    this.currentViewer("play");
                                } else {
                                    // MainViewModel.RoutingTable.showDocumentEditorView({ caseId: this.caseId, docId: entry.Id, refresh: true });
                                    this.currentViewer("wopi");
                                    this.updateStamps();
                                }

                                $('#DocumentViewerModal').modal('show');
                            }
                        },
                        {
                            'icon': 'paperclip',
                            'name': 'Workflow',
                            'action': () => {
                                this.showWorkflowModal(entry.Id, entry.CaseId);
                            }
                        },
                        {
                            'icon': 'user-circle',
                            'name': 'Delegieren',
                            'action': () => {
                                this.pickClerkDelegation(entry);
                            }
                        },
                        {
                            'icon': 'cloud-download-alt',
                            'name': 'Download',
                            'action': () => {
                                if (entry.Id === '') {
                                    entry.Id = entry.DocDBIds[0];
                                    entry.SearchDate = entry.DocumentDate;
                                }
                                this.download(entry.Subject, entry.Id, entry.SearchDate);
                            }
                        }
                    ];

                    entry.Workflow = this.wfNames[entry.WorkflowId];

                    return entry;
                });

            this.caseEntries(entries);
        } catch (e) {
            console.log(e);
        }

    }

    async sendMail(entry) {
        this.currentViewer("compose");
        this.documentToEmail({
            entry: entry,
            email: this.emailAddress()
        });
        $('#DocumentViewerModal').modal('show');
    }

    deleteEntryid = ko.observable("");

    async remove(id: string) {
        $("#DeleteEntry").modal('show');
        this.deleteEntryid(id);
    }

    async actuallydelete() {
        await RNSAPI.deleteRecord(ko.toJS(this.deleteEntryid));
        await this.loadEntries();
        $("#DeleteEntry").modal('hide');
    }

    async download(name: string, id: string, date?: Date) {
        let element = document.createElement('a');
        let doc;
        if (date)
            doc = (await RNSAPI.getDocumentDataByName(id, date)).Payload.Document;
        else
            doc = (await RNSAPI.getDocumentData(id)).Payload.Document;
        //server side bug in the ActiveX Component => OleType is doc instead of rtf
        let IsRTF = atob(doc.DocumentData.substr(0, 50)).indexOf("rtf") !== -1;
        let type = IsRTF ? "rtf" : doc.OLEType.trim().toLowerCase();

        let blob = Utils.base64ToBlob(doc.DocumentData, 'application/octet-stream');

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob);
        } else {
            element.setAttribute('href', 'data:application/octet-stream;charset=utf-16le;base64,' + doc.DocumentData);
            element.setAttribute('download', `${name.trim()}.${type}`);

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        }
    }

    prevSub = [];    

    resetCreateTextModalForm() {
        this.persons([]);
        this.person(null);
        this.beteiligte([]);
        this.beteiligter(null);
        this.selectedUserType('');
        this.selectedTextGWorkflow('');
        this.dictationMark('');
    }

    private fixSchreibenAnGericht(): void {
        if(this.selectedTemplateCategory() === "Gericht") {
            const docTemplates = ko.toJS(this.documentTemplates());
            const f = docTemplates.filter(x => x.Description.includes("Schreiben an Gericht"));
            let id;
            if(f) {
                for(const data of f) {
                    id = data.Id;
                    docTemplates.forEach( (item, index) => {
                        if(item === data) docTemplates.splice(index,1);
                    });
                }
                docTemplates.push({ Description: "Schreiben an Gericht", Id: id });
            }
            this.documentTemplates(docTemplates);
        }
    }

    private uploadDocument: boolean = false;
    
    private triggetTemplateChange(): void {
        
        if(this.uploadDocument) {
            this.persons(this.allPersons().filter(x => x.keyword !== ""));
            return;
        }
        
        this.beteiligter(null);
        this.beteiligte([]);

        if (this.documentTemplateStructur().length > 0)
            this.selectedTemplateCategory(this.documentTemplateStructur()[0].Templates);
        const template = this.documentTemplateStructur().filter((x) => x.CategoryName === this.selectedTemplateCategory())[0];
        this.documentTemplates([]);
        if(template) {
            for(let i=0; i<template.Templates.length; i++) {
                this.documentTemplates.push({ Description: template.Templates[i].Description, Id: template.Templates[i].Id });
           }
           this.fixSchreibenAnGericht();
   
           this.selectedDocumentTemplate(template.Templates[0].Id);
   
           switch(this.selectedTemplateCategory()) {
                case "Mandant":
                    this.persons(this.allPersons().filter(x => x.type.toLowerCase() === "mandant"));
                break;
                case "Gegner":
                    this.persons(this.allPersons().filter(x => x.type.toLowerCase() === "gegner"));
                break;
                case "Behörde":
                    this.persons(this.allPersons().filter(x => x.type.toLowerCase() === "behörde"));
                break;
                case "Gericht":
                    this.persons(this.allPersons().filter(x => x.type.toLowerCase() === "gericht"));
                break;
                case "Haftpflicht Mandant":
                    this.persons(this.allPersons().filter(x => x.type.toLowerCase() === "haftpflicht mandant"));
                break;
           }
   
           this.person(this.persons()[0]);
   
           this.changeDocumentTemplate(null, null);
        }
    }

    constructor(params) {
        if (params) {
            this.caseId = params.caseId;
            this.loadEntries();
        }

        this.getPersons();
        this.loadPredefinedStamps();
        this.getUsers();

        if (params.openCreateTextModal()) {
            this.createText();
        }

        this.isDocumentsEmpty = ko.computed({
            owner: this,
            read: () => {
                return this.caseEntries().length === 0;
            }
        });

        this.workflowChanged = (obj, event) => {
            if (event.originalEvent) {
                if (this.selectedWorkflow().indexOf(this.selectedWorkflowItem()) === -1) {
                    this.selectedWorkflow.push(this.selectedWorkflowItem());
                }
            }
        }


        this.changeTemplateCategory = function (obj, event) {
            if(event.originalEvent) {
                this.documentTemplates([]);
                this.triggetTemplateChange();
                
            }
        };

       
    }

   
}

let html = fs.readFileSync(__dirname + '/dokumente.html', 'utf8');

ko.components.register("dokumente-view", {
    viewModel: Dokumente,
    template: html
});

